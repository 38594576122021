<template>
    <div>
        <img :src="title" class="media_release_title" />
        <span class="media_release_span">{{ remark }}</span>
    </div>
</template>

<script>
export default {
    props: {
        title:String, 
        remark:String
    },

    data() {
        return {
        }
    },
    methods: {},
}
</script>

<style lang=scss scoped>
.media_release_title {
    display: block;
    width: 15rem;
    margin: 0 auto;
    margin-bottom: 1.5rem;
}

.media_release_span {
    display: block;
    color: #999;
    margin-bottom: 2.5rem;
    text-align: center;
    font-size: 1.22rem;
}
</style>